const getters = {
  sidebar: (state) => state.app.sidebar,
  language: (state) => state.app.language,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  token: (state) => state.user.token,
  menuId: (state) => state.operation.menuId,
  returnAddress: (state) => state.operation.returnAddress,
  search: (state) => state.operation.search,
  cardType: (state) => state.operation.cardType,
  userInfo: (state) => state.user.userInfo,
  background: (state) => state.app.background,
};
export default getters;
