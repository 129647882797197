import request from "@/utils/request";
const name = "/aimber";

export function NotificationCount(data) {
  return request({
    url: name + "/art/notification/count",
    method: "get",
    params: data,
  });
}

export function NotificationPageable(data) {
  return request({
    url: name + "/art/notification/pageable",
    method: "post",
    data
  });
}


