import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

let originPush = Router.prototype.push; //备份原push方法

Router.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    //如果传了回调函数，直接使用
    originPush.call(this, location, resolve, reject);
  } else {
    //如果没有传回调函数，手动添加
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};

const constantRoutes = [
  //首页
  {
    path: "/",
    name: "home",
    component: () => import("../views/home.vue"),
    meta: { customValue: "true" }, // 添加自定义字段
  },
  {
    path: "/works",
    name: "works",
    meta: {
      keepAlive: true,
    },
    component: () => import("../views/works.vue"),
  },

  //登录
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  //测试
  {
    path: "/cesi",
    component: () => import("@/views/cesi/index"),
    hidden: true,
  },
  {
    path: "/countdown",
    component: () => import("@/views/countdown/index"),
    hidden: true,
  },

  //推荐页
  {
    path: "/guide",
    name: "guide",
    component: () => import("../views/guide/index"),
  },
  //推荐页
  {
    path: "/share",
    name: "share",
    component: () => import("../views/share/index"),
    meta: { customValue: "true" }, // 添加自定义字段

  },
  //个人主页
  {
    path: "/userHomepage",
    name: "userHomepage",
    // meta: {
    //   keepAlive: true
    // },
    component: () => import("../views/myHomepage/index"),
  },
  //个人主页
  {
    path: "/personalCenter",
    name: "personalCenter",
    redirect: "/personalCenter/worksList",
    component: () => import("@/views/personalCenter/index"),
    
    children: [
      {
        path: "/personalCenter/worksList",
        name: "worksList",
        component: () => import("@/views/personalCenter/worksList/index"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/personalCenter/fansFollow",
        name: "fansFollow",
        component: () => import("@/views/personalCenter/fansFollow/index"),
      }
    ],
    
  },
  //用户编辑
  {
    path: "/editInfo",
    name: "editInfo",
    redirect: "/editInfo/manageWorks",
    component: () => import("@/views/editInfo/index"),
    meta: {
      keepAlive: true,
    },
    children: [
      {
        path: "/editInfo/manageWorks",
        name: "manageWorks",
        component: () => import("@/views/editInfo/manageWorks/index"),
        meta: { customValue: "true" }, // 添加自定义字段
      },
      {
        path: "/editInfo/protectRecord",
        name: "protectRecord",
        component: () => import("@/views/editInfo/protectRecord/index"),
        meta: { customValue: "true" }, // 添加自定义字段
      },
      {
        path: "/editInfo/editData",
        name: "editData",
        component: () => import("@/views/editInfo/editData/index"),
        meta: { customValue: "true" }, // 添加自定义字段
      },
      {
        path: "/editInfo/setUp",
        name: "setUp",
        component: () => import("@/views/editInfo/setUp/index"),
        meta: { customValue: "true" }, // 添加自定义字段
      },
      {
        path: "/editInfo/blacklist",
        name: "blacklist",
        component: () => import("@/views/editInfo/blacklist/index"),
        meta: { customValue: "true" }, // 添加自定义字段
      },
      {
        path: "/editInfo/protocol",
        name: "protocols",
        component: () => import("@/views/editInfo/protocol/index"),
        meta: { customValue: "true" }, // 添加自定义字段
      },
      {
        path: "/editInfo/workOrderList",
        name: "workOrderList",
        component: () => import("@/views/editInfo/workOrderList/index"),
        meta: { customValue: "true" }, // 添加自定义字段
      },
      {
        path: "/editInfo/certificateRecord",
        name: "certificateRecord",
        component: () => import("@/views/editInfo/certificateRecord/index"),
        // meta: { customValue: "true" }, // 添加自定义字段
      },
      {
        path: "/editInfo/paymentRecords",
        name: "paymentRecords",
        component: () => import("@/views/editInfo/paymentRecords/index"),
        meta: { customValue: "true" }, // 添加自定义字段
      },
    ],
  },
  //作品详情
  {
    path: "/workDetails",
    name: "workDetails",
    component: () => import("../views/workDetails/index"),
  },
  //作品上传
  {
    path: "/workUpload",
    name: "workUpload",
    component: () => import("../views/workUpload/index"),
  },
  //工单详情
  {
    path: "/workOrderDetails",
    name: "workOrderDetails",
    component: () => import("../views/workOrderDetails/index"),
  },
  // 通知
  {
    path: "/myNotice",
    name: "myNotice",
    component: () => import("../views/myNotice/index"),
  },
  //搜索
  {
    path: "/search",
    name: "search",
    meta: {
      keepAlive: true,
    },
    component: () => import("../views/searchs/index"),
  },
  //协议
  {
    path: "/protocol",
    name: "protocol",
    component: () => import("@/views/protocol/index"),
  },
  //购买琥珀
  {
    path: "/purchase",
    name: "purchase",
    component: () => import("@/views/purchase/index"),
    meta: { customValue: "true" }, // 添加自定义字段

  },
];
const createRouter = () =>
  new Router({
    mode: "history", // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });
const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
