import request from "@/utils/request";
const name = "/aimber";

export function backgroudImages(data) {
  return request({
    url: name + "/public/background-image",
    method: "GET",
    params: data,
  });
}

export function login(data) {
  return request({
    url: name + "/customlogin",
    method: "post",
    data,
  });
}
export function CodeLogin(data) {
  return request({
    url: name + "/customlogin",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;",
    },
    data,
  });
}

//置换token
export function RenewalJwtToken(data) {
  return request({
    url: name + "/user/renewal-token",
    method: "get",
    params: data,
  });
}


//微信
export function QrCode(data) {
  return request({
    url: name + "/public/qrcode",
    method: "get",
    params: data,
  });
}
export function QueryQrCode(data) {
  return request({
    url: name + "/public/query-qrcode",
    method: "get",
    params: data,
  });
}

//绑定微信
export function BindWechar(data) {
  return request({
    url: name + "/user-profile/bind-wechar",
    method: "get",
    params: data,
  });
}


export function SendSmsCode(data) {
  return request({
    url: name + "/public/sms/send-code",
    method: "get",
    params: data,
  });
}

export function SendMarkedPhoneSmsCode(data) {
  return request({
    url: name + "/user/send-code",
    method: "get",
    params: data,
  });
}

export function UserLogout() {
  return request({
    url:name +  '/logout',
    method: 'post'
  })
}

//绑定/解绑
export function LoinType(data) {
  return request({
    url: name + "/user-profile/login-type",
    method: "get",
    params: data,
  });
}
// 解绑微信
export function UnindWechar(data) {
  return request({
    url: name + "/user-profile/unbind-wechar",
    method: "get",
    params: data,
  });
}

//绑定手机
export function BindPhone(data) {
  return request({
    url: name + "/user-profile/bind-phone",
    method: "get",
    params: data,
  });
}
//解绑手机
export function UnindPhone(data) {
  return request({
    url: name + "/user-profile/unbind-phone",
    method: "get",
    params: data,
  });
}

//查询手机是否绑定
export function CheckTelExisted(data) {
  return request({
    url: name + "/user-profile/check-principal",
    method: "get",
    params: data,
  });
}

//微信绑定 查询手机是否绑定
export function CheckTelExistedPublic(data) {
  return request({
    url: name + "/public/sms/check-telphone",
    method: "get",
    params: data,
  });
}
// 换绑验证手机验证码
export function VefifyPhoneSmsCode(data) {
  return request({
    url: name + "/user/verify-sms-code",
    method: "get",
    params: data,
  });
}